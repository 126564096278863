<template>
	<div v-if="loaded">
		<img style="max-width: 100vw; max-height: 80vh" :src="filePath" />
		<br />
		<button @click="toggleAnswer">Zeige Lösung</button>
		<div v-if="showAnswer">{{ answer }}</div>
		<br />
		<button @click="wrongAnswer">wusste ich nicht</button>
		<button @click="rightAnswer">wusste ich</button>
	</div>
</template>

<script>
	import { mapGetters, mapMutations } from "vuex";
	let apiUrl = "https://pathoquizserver.dres-frentzen.de";
	export default {
		name: "Dissection",
		data() {
			return {
				filePath: null,
				answer: null,
				showAnswer: false,
				loaded: false,
			};
		},
		computed: {
			...mapGetters(["sessionId", "overallCounter"]),
			dissectionNumber() {
				return this.$route.params.dissection;
			},
		},
		mounted() {
			this.loadData();
		},
		methods: {
			...mapMutations([
				"incrementCorrectCounter",
				"incrementWrongCounter",
				"incrementOverallCounter",
				"addWrongDissection",
			]),
			toggleAnswer() {
				this.showAnswer = !this.showAnswer;
			},
			wrongAnswer() {
				this.incrementWrongCounter();
				this.addWrongDissection(this.answer);
				this.nextDissection();
			},
			rightAnswer() {
				this.incrementCorrectCounter();
				this.nextDissection();
			},
			nextDissection() {
				this.incrementOverallCounter();
				this.$router.push(`/quiz/${this.overallCounter}`);
			},
			loadData() {
				this.loaded = false;
				this.showAnswer = false;
				console.log(this.$route.params.dissection);
				this.$axios({
					method: "get",
					url: `${apiUrl}/quiz`,
					params: {
						number: this.$route.params.dissection,
						sessionId: this.sessionId,
					},
				})
					.then((res) => {
						this.filePath = `${apiUrl}/public/${res.data.dissection.filePath}`;
						this.answer = res.data.dissection.name;
						this.loaded = true;
						console.log(res);
					})
					.catch((err) => {});
			},
		},
		watch: {
			"$route.params.dissection": function () {
				this.loaded = false;
				this.showAnswer = false;
				console.log(this.$route.params.dissection);
				this.$axios({
					method: "get",
					url: `${apiUrl}/quiz`,
					params: {
						number: this.$route.params.dissection,
						sessionId: this.sessionId,
					},
				})
					.then((res) => {
						this.filePath = `${apiUrl}/public/${res.data.dissection.filePath}`;
						this.answer = res.data.dissection.name;
						this.loaded = true;
						console.log(res);
					})
					.catch((err) => {});
			},
		},
	};
</script>

<style lang="scss" scoped></style>
