<template>
	<div>
		<button @click="startSession">Quiz starten</button>
	</div>
</template>

<script>
	import { mapMutations } from "vuex";
	import Quiz from "./Quiz.vue";
	let apiUrl = "https://pathoquizserver.dres-frentzen.de";
	export default {
		name: "StartSession",
		data() {
			return {
				started: false,
			};
		},
		components: {
			Quiz,
		},
		methods: {
			...mapMutations(["setSessionId", "reset"]),
			async startSession() {
				this.$axios({
					method: "POST",
					url: `${apiUrl}/session`,
				})
					.then((res) => {
						console.log(res);
						this.started = true;
						this.reset();
						this.setSessionId(res.data.sessionId);
						this.$router.push("/quiz/1");
					})
					.catch((err) => {});
				// alert("Start");
			},
		},
	};
</script>

<style lang="scss" scoped></style>
