import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		sessionId: null,
		overallCounter: 1,
		correctCounter: 0,
		wrongCounter: 0,
		wrongDissections: [],
		correctDissections: [],
	},
	getters: {
		sessionId: (state) => {
			return state.sessionId;
		},
		overallCounter: (state) => {
			return state.overallCounter;
		},
		correctCounter: (state) => {
			return state.correctCounter;
		},
		wrongCounter: (state) => {
			return state.wrongCounter;
		},
		wrongDissections: (state) => {
			return state.wrongDissections;
		},
	},
	mutations: {
		reset: (state, value) => {
			state.wrongDissections = [];
			state.overallCounter = 1;
			state.correctCounter = 0;
			state.wrongCounter = 0;
		},
		setSessionId: (state, sessionId) => {
			state.sessionId = sessionId;
		},
		incrementOverallCounter(state) {
			state.overallCounter = state.overallCounter + 1;
		},
		incrementCorrectCounter(state) {
			state.correctCounter = state.correctCounter + 1;
		},
		incrementWrongCounter(state) {
			state.wrongCounter = state.wrongCounter + 1;
		},
		addWrongDissection(state, dissectionName) {
			console.log(dissectionName);
			let wrongDissections = [...state.wrongDissections, dissectionName];
			wrongDissections = wrongDissections.sort(function (a, b) {
				return a.localeCompare(b); //using String.prototype.localCompare()
			});
			state.wrongDissections = wrongDissections;
		},
	},
	actions: {},
	modules: {},
	plugins: [createPersistedState()],
});
