<template>
	<div>
		<button>falsch</button>
		<button>korrekt</button>
	</div>
</template>

<script>
	export default {
		name: "QuizSlide",
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped></style>
