<template>
	<div>
		<!-- <QuizSlide /> -->
		Sitzungsnummer
		{{ sessionId }}
		<br />
		<button @click="restart">Quiz neu starten</button>
		<br />
		Präparat: {{ overallCounter }} richtige: {{ correctCounter }} falsche:
		{{ wrongCounter }}
		<br />
		<router-view />
		<br />

		<br />
		<br />
		Falsche Antworten:
		<div>
			<div v-for="answer in wrongDissections" :key="answer">
				{{ answer }}
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapMutations } from "vuex";
	import QuizSlide from "./QuizSlide.vue";
	let apiUrl = "https://pathoquizserver.dres-frentzen.de";
	export default {
		name: "Quiz",
		data() {
			return {
				// overallCounter: 0,
				// correctCounter: 0,
				// wrongCounter: 0,
			};
		},
		components: {
			QuizSlide,
		},
		computed: {
			...mapGetters([
				"sessionId",
				"overallCounter",
				"correctCounter",
				"wrongCounter",
				"wrongDissections",
			]),
		},
		methods: {
			...mapMutations(["incrementOverallCounter", "reset", "setSessionId"]),
			showAnswer() {},
			// nextDissection() {
			// 	this.incrementOverallCounter();
			// 	this.$router.push(`/quiz/${this.overallCounter}`);
			// },
			addRight() {
				this.correctCounter = this.correctCounter + 1;
			},
			addWrong() {
				this.wrongCounter = this.wrongCounter + 1;
			},
			restart() {
				console.log("Restart");
				this.$axios({
					method: "POST",
					url: `${apiUrl}/session`,
				})
					.then((res) => {
						console.log(res);
						this.started = true;
						this.reset();
						this.setSessionId(res.data.sessionId);
						this.$router.push("/quiz/1");
					})
					.catch((err) => {});
			},
		},
	};
</script>

<style lang="scss" scoped></style>
