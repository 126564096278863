import Vue from "vue";
import VueRouter from "vue-router";
import StartSession from "../components/StartSession.vue";
import Quiz from "../components/Quiz.vue";
import Dissection from "../components/Dissection";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "StartSession",
		component: StartSession,
	},
	{
		path: "/quiz",
		name: "Quiz",
		component: Quiz,
		children: [
			{
				path: ":dissection",
				name: "Dissection",
				component: Dissection,
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
